import VueCookies from 'vue-cookies';
const JWT_TOKEN_NAME = 'jwt';
const USER_INFO_NAME = 'user';
const USER_DATA_NAME = 'user-data';
const getBearerToken = function () {
  let jwt = getJwtToken();
  return jwt && jwt.token || null;
}

const getJwtToken = function () {
  var jwt = VueCookies.isKey(JWT_TOKEN_NAME)?VueCookies.get(JWT_TOKEN_NAME):null;
  var userData = VueCookies.isKey(USER_DATA_NAME)?VueCookies.get(USER_DATA_NAME):null;
  if(jwt!=null && userData!= null)
    return {'token':jwt.token,'refreshToken': jwt.refreshToken,'permissions':userData.permissions,'zonePermissions': userData.zonePermissions,'companies':userData.companies,'isSystemAdmin': userData.isSystemAdmin,'isClientAdmin':userData.isClientAdmin}
  else{
    return null;
  }
}

const getRefreshToken = function () {
  let jwt = getJwtToken();
  return jwt && jwt.refreshToken || null;
}

const getUserToken = function () {
  return VueCookies.isKey(USER_INFO_NAME)?VueCookies.get(USER_INFO_NAME):null;
}

const setToken = function (name, token) {
  if(name==JWT_TOKEN_NAME && token != null && token != undefined && token.token != null && token.token != undefined){
    //Filtering out unneeded properties if it's the JWT token to reduce cookie size. 
    VueCookies.set(JWT_TOKEN_NAME,{'token':token.token,'refreshToken': token.refreshToken},{expires: ''});
    VueCookies.set(USER_DATA_NAME,{'permissions':token.permissions,'zonePermissions': token.zonePermissions,'companies':token.companies,'isSystemAdmin': token.isSystemAdmin,'isClientAdmin':token.isClientAdmin},{expires: ''});
  }else{
    VueCookies.set(name, token,{expires: ''});
  }
}

const removeToken = function(name){
  VueCookies.remove(name);
}

export default {
  keys: {
    'jwt': JWT_TOKEN_NAME,
    'user': USER_INFO_NAME
  },
  getBearerToken: getBearerToken,
  getJwtToken: getJwtToken,
  getRefreshToken: getRefreshToken,
  getUserToken: getUserToken,
  setToken: setToken,
  removeToken: removeToken
}
